<style scoped>
.document-name {
  background: #194e76;
  color: white;
  font-size: 20px;
  padding: 10px 30px;
  font-weight: 700;
}
.page-wrapper {
  background: #f6f7fa;
}
.preview-image-wrapper {
  min-height: calc(100vh - 50px);
  display: grid;
  padding: 20px;
  place-items: center;
}
.preview-image-wrapper img {
  width: 50%;
  margin: 10px;
}
</style>

<template>
  <div class="page-wrapper">
    <p class="document-name">
      {{ this.document_type }}
    </p>
    <div class="preview-image-wrapper">
      <img :src="this.image_url" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      document_type: "",
      image_url: "",
    };
  },
  methods: {},
  mounted() {
    console.log(this.$route);
    this.document_type = this.decrypt(this.$route.params.document);
    this.image_url = this.decrypt(this.$route.params.image);
  },
};
</script>
